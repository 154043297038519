<form [formGroup]="formGroup">
  
  <mat-form-field appearance="outline">
    <mat-label>מייל</mat-label>
    <input matInput formControlName="email" type="email" placeholder="מייל">
    <mat-icon matSuffix>email</mat-icon>
  </mat-form-field>

  <br>

  <div class="btns">

    <button mat-raised-button color="accent" (click)="save()" [disabled]="formGroup.invalid">שמירה</button>

  </div>

</form>
