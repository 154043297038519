<form [formGroup]="formGroup">

  <mat-form-field appearance="outline">
    <mat-label>מייל</mat-label>
    <input matInput formControlName="email" type="email" placeholder="מייל">
    <mat-icon matSuffix>email</mat-icon>
  </mat-form-field>

  <br>

  <mat-form-field appearance="outline">
    <mat-label>סיסמא</mat-label>
    <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" placeholder="סיסמא">
    <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'הסתר סיסמה'"
      [attr.aria-pressed]="hidePassword">
      <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </mat-form-field>

  <br>

  <div class="btns">

    <button mat-raised-button color="accent" (click)="login()">
      התחבר
    </button>

    <span class="ks-flex-spacer"></span>
    <a mat-button color="accent" [routerLink]="['forgot-password']">שחכתי את הסיסמא</a>
    <span class="ks-flex-spacer"></span>

    <button mat-stroked-button color="accent" [routerLink]="['/register']">הרשמה</button>

  </div>

  <div class="or">
    <span class="ks-flex-spacer margin-left"></span>
    <span>או</span>
    <span class="ks-flex-spacer margin-right"></span>
  </div>

  <img class="login-with-google" src="../../assets/btn_google_signin_dark_normal.png" (click)="loginWithGoogle()">


</form>