import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hidePassword = true;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router
  ) { }

  formGroup = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });

  ngOnInit(): void {
    this.authService.userUpdated$.subscribe(user => {
      if (user) {
        this.router.navigate(['application']);
      }
    });
  }

  login() {
    const value = this.formGroup.value;
    this.authService.login(value.email, value.password);
  }

  loginWithGoogle() {
    this.authService.loginWithGoogle();
  }

}
