import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RegisterComponent } from './login/register/register.component';
import { AuthGuard } from './guards/auth.guard';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ForgotPasswordEmailSentComponent } from './login/forgot-password-email-sent/forgot-password-email-sent.component';
import { LoginComponent } from './login/login/login.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'verify-email', redirectTo: '/application/families', pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'forgot-password-email-sent', component: ForgotPasswordEmailSentComponent },

  {
    path: 'application',
    loadChildren: () =>
      import('./application/application.module').then((m) => m.ApplicationModule)
  },

  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule)
  },

  {
    path: 'site',
    loadChildren: () =>
      import('./site/site.module').then((m) => m.SiteModule)
  },

  {
    path: 'templates',
    loadChildren: () =>
      import('./templates/templates.module').then((m) => m.TemplatesModule)
  },

  {
    path: 'kehila',
    loadChildren: () =>
      import('./kehila/kehila.module').then((m) => m.KehilaModule)
  },

  { path: '', redirectTo: '/application/families', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
