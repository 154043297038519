import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  formGroup = this.fb.group({
    email: ['', Validators.required],
  });

  ngOnInit(): void {
  }

  save() {
    this.authService.sendPasswordResetEmail(this.formGroup.controls.email.value).then(_ =>
      this.router.navigate(['forgot-password-email-sent']))
      .catch(
        _ => this.snackBar.open('המייל שהוזן לא קיים במערכת', '', {
          duration: 5000,
        })
      );
  }
}
