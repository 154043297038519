import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from 'models/user';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject } from 'rxjs/internal/Subject';
import { retryWhen, delay, take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: Partial<User>;
  private firebaseUser: firebase.User;

  private userUpdatedSubject = new BehaviorSubject<User>(null);
  public userUpdated$ = this.userUpdatedSubject.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private firestore: AngularFirestore
  ) {
    this.afAuth.authState.subscribe(firebaseUser => {
      this.firebaseUser = firebaseUser;
      if (firebaseUser) {
        this.firestore.collection('users').doc(firebaseUser.uid).valueChanges()
          .pipe(retryWhen(error => error.pipe(delay(3000), take(5)))).subscribe(user => {
            this.user = user;
            this.userUpdatedSubject.next(user as User);
          });
      } else {
        this.user = null;
        this.userUpdatedSubject.next(null);
      }
    });
  }


  get User(): Partial<User> {
    return this.user;
  }

  // get UserObs(): Observable<Partial<User>> {
  //   // return this.user;
  // }


  async login(email: string, password: string) {
    await this.afAuth.signInWithEmailAndPassword(email, password);
    this.router.navigate(['application']);
  }

  async register(email: string, password: string) {

    this.firebaseUser = (await this.afAuth.createUserWithEmailAndPassword(email, password)).user;
    if (this.firebaseUser) {
      await this.firebaseUser.updateProfile({ displayName: email });
      this.sendEmailVerification();
    }
    this.router.navigate(['application']);

  }

  async sendEmailVerification() {
    await (await this.afAuth.currentUser).sendEmailVerification();
    this.router.navigate(['verify-email']);
  }

  async sendPasswordResetEmail(passwordResetEmail: string) {
    return await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  async logout() {
    await this.afAuth.signOut();
    this.user = null;
    this.router.navigate(['application/families']);
  }

  async loginWithGoogle() {
    const firebaseUser = await this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
    // await firebaseUser.user.updateProfile({ displayName: name });
    this.router.navigate(['application']);
  }

}
