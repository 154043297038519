<div class="container">

  <h2>
    נשלח מייל לשחזור הסיסמא
  </h2>

  <br>

  <div class="btns">
    <button mat-raised-button color="accent" [routerLink]="['application/families']">חזור לדף הראשי</button>
  </div>

</div>
