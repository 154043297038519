export const environment = {
  production: false,
  application: 'kesher',
  firebase: {
    kesherashotfot: {
      apiKey: 'AIzaSyArW9nlEPHJOP43OTa7OjZB3NBn78O2LKk',
      authDomain: 'kesherashotfot.firebaseapp.com',
      databaseURL: 'https://kesherashotfot.firebaseio.com',
      projectId: 'kesherashotfot',
      storageBucket: 'kesherashotfot.appspot.com',
      messagingSenderId: '234397525209',
      appId: '1:234397525209:web:12500393128363e84d8ac8',
      measurementId: 'G-0RH3QJJXL2'
    },
    basket: {
      apiKey: 'AIzaSyA0hJRmg7aS24P7fXV8gw-XFHCtMfPuo8E',
      authDomain: 'basket-b8e9f.firebaseapp.com',
      projectId: 'basket-b8e9f',
      storageBucket: 'basket-b8e9f.appspot.com',
      messagingSenderId: '16797070961',
      appId: '1:16797070961:web:cde4d7a4491683c7f70dd0',
      measurementId: 'G-GX9H49609P'
    }
  }
};
