import { Component } from '@angular/core';
import { LayoutService } from './services/layout.service';
import { NavigationEnd, Router } from '@angular/router';
declare let ga: (arg0: string, arg1: string, arg2: string ) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kesher-a-shotfot';

  constructor(
    public layoutService: LayoutService,
    public router: Router
  ) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview', '');
    //   }
    // });
  }

}
