import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  sidenavOpened = false;

  get cameFromOutside() {
    return localStorage.cameFromOutside || false;
  }

  setCameFromOutside(value: boolean) {
    localStorage.cameFromOutside = value;
  }




}


